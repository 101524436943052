import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByKey',
  pure: true,
})
export class GroupByKeyPipe implements PipeTransform {
  transform(array: Array<any>, key: string): Array<any> {
    let grouped: { key: any; values: any[] }[] = array.reduce((groups: { key: any; values: any[] }[], item) => {
      const val = item[key];
      groups = groups || [];
      let group: { key: any; values: any[] } = groups.find((g) => g.key === val);
      if (group) {
        group.values.push(item);
      } else {
        group = { key: val, values: [item] };
        groups.push(group);
      }

      return groups;
    }, []);

    return grouped;
  }
}

// USAGE:
// <div *ngFor="item of someArray | groupByKey: 'key'" >
// </div>

// PRODUCES
// var cars = [{ make: 'audi', model: 'r8', year: '2012' }, { make: 'audi', model: 'rs5', year: '2013' }, { make: 'ford', model: 'mustang', year: '2012' }, { make: 'ford', model: 'fusion', year: '2015' }, { make: 'kia', model: 'optima', year: '2012' }];
// -> [{key:"audi",value:[{make:"audi",model:"r8",year:"2012"},{make:"audi",model:"rs5",year:"2013"}]},{key:"ford",value:[{make:"ford",model:"mustang",year:"2012"},{make:"ford",model:"fusion",year:"2015"}]},{key:"kia",value:[{make:"kia",model:"optima",year:"2012"}]}]
